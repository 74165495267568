import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  Modal
} from "react-bootstrap";

import AuthenContext from "../../models/AuthenContext";
import ButtonLoading from "../../components/Controls/ButtonLoading";
import Draw from "../../components/Controls/Draw";
import ImageLib from "../../libs/ImageLib";

function Signature() {
  const {authenUser, setIsLoginUser} = useContext(AuthenContext);

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const initForm = {
    "sign_type": 0,
    "sign_data": null,
    "sign_ref": null,
    "sign_file": null,
    "sign_change": false
  };

  const [showForm, setShowForm] = useState(false);
  const [form, setForm] = useState(initForm);
  const [formError, setFormError] = useState(initForm);
  const [loadingSave, setLoadingSave] = useState(false);
  const [clearDrawSign, setClearDrawSign] = useState(false);
  const canvasSign = useRef();
  const canvasPreviewSign = useRef();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if(showForm && form.sign_data) {
      let canvas = form.sign_type == 0 ? canvasSign.current : canvasPreviewSign.current;
      let ctx = canvas.getContext("2d");
      let image = new Image();
      image.onload = () => {
        ctx.drawImage(image, 0, 0);
      };
      image.src = form.sign_data;
    }
  }, [showForm]);

  const handleFormInputChange = (e) => {
    let key = e.target.name, value;
    
    if(e.target.type == "file") {
      let file = e.target.files[0];
      let fr = new FileReader();
      fr.onload = () => {
        value = {
          "name": file.name,
          "size": file.size,
          "type": file.type,
          "base64": fr.result
        };

        if(key == "sign_file") {
          let canvas = canvasPreviewSign.current;
          let ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          let image = new Image();
          image.onload = () => {
            ImageLib.drawImageScaleCanvas(image, ctx);

            setForm({
              ...form,
              [key]: value,
              "sign_change": true,
              "sign_data": canvasPreviewSign.current.toDataURL()
            });
          };
          image.src = fr.result;
        }
        else {
          setForm({
            ...form,
            [key]: value
          });
        }
      };
      fr.readAsDataURL(file);
      return;
    }
    else {
      value = e.target.value;
    }

    setForm({
      ...form,
      [key]: value
    });
  }

  const handleDrawingEnd = (e) => {
    setForm({
      ...form,
      "sign_change": true,
      "sign_data": canvasSign.current.toDataURL()
    });
  }

  const loadData = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/profile.get", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setForm({
          ...form,
          ...data
        });
        setFormError(data);
        setShowForm(true);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const formSubmit = (e) => {
    e.preventDefault();

    let is_valid = true;
    let errors = initForm;

    if(is_valid) {
      setLoadingSave(true);

      axios.post(process.env.REACT_APP_API_URL + "/school/signature.set", {
        "api_key": process.env.REACT_APP_API_KEY,
        "token": authenUser.token,
        ...form
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          loadData();
        }

        setAlert({
          "show": true,
          "text": res.data.msg
        });
      })
      .catch((ex) => {
        console.log(ex);
      })
      .finally(() => {
        setLoadingSave(false);
      });
    }
    else {
      setFormError({
        ...formError,
        ...errors
      });
    }
  }

  return (
    <>
      <Container>
        <Row className="justify-content-md-center align-items-center">
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4" className="text-center">แนบลายเซ็น</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form noValidate onSubmit={formSubmit}>
                  <Form.Group>
                    <Row className="justify-content">
                      <Col sm="auto">
                        <Form.Check type="radio" id="sign_type_0" name="sign_type" value="0" label="เซ็นเก็บไว้" checked={form.sign_type == 0} onChange={handleFormInputChange} custom />
                      </Col>
                      <Col sm="auto">
                        <Form.Check type="radio" id="sign_type_1" name="sign_type" value="1" label="แนบไฟล์" checked={form.sign_type == 1} onChange={handleFormInputChange} custom />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <div>
                      <div className={"w-100" + (form.sign_type != 0 ? " d-none" : "")}>
                        <div className="box-sign-wrap" style={{"width": "482px"}}>
                          <div className="box-sign-mark">เซ็นลายเซ็นที่นี่</div>
                          <Draw id="draw-sign" width="480" height="200" ref={canvasSign} clearDraw={clearDrawSign} setClearDraw={setClearDrawSign} onDrawingEnd={handleDrawingEnd} color="#00f" className="box-sign"></Draw>
                        </div>
                        <Button type="button" variant="danger" className="btn-fill" onClick={() => setClearDrawSign(true)}><i className="fas fa-eraser"></i> ล้าง</Button>
                      </div>
                      <div className={"w-100" + (form.sign_type != 1 ? " d-none" : "")}>
                        <div className="box-sign-wrap" style={{"width": "482px"}}>
                          <div className="box-sign-mark"><i className="far fa-image box-sign-icon"></i></div>
                          <canvas id="preview-sign" width="480" height="200" ref={canvasPreviewSign} className="box-sign"></canvas>
                        </div>
                        <label className="btn btn-primary btn-fill mr-sm-2">
                          <i className="fas fa-paperclip"></i> แนบลายเซ็น
                          <Form.File.Input name="sign_file" onChange={handleFormInputChange} accept=".jpg,.jpeg,.png,.gif" className="d-none" />
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                  <div className="text-center">
                    <ButtonLoading type="submit" icon="fas fa-check" className="btn btn-info btn-fill" loading={loadingSave}>บันทึก</ButtonLoading>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default Signature;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import interact from "interactjs";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import * as pdfjsWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  Modal
} from "react-bootstrap";

import AuthenContext from "../../models/AuthenContext";
import ButtonLoading from "../../components/Controls/ButtonLoading";
import Page from "../../components/Controls/Page";

function Doc(props) {
  const history = useHistory();

  const {authenUser, setIsLoginUser} = useContext(AuthenContext);

  let param_id = new URLSearchParams(props.location.search).get("id");
  const [paramId, setParamId] = useState(param_id);

  let param_do = new URLSearchParams(props.location.search).get("do");
  const [paramDo, setParamDo] = useState(param_do);

  const [showForm, setShowForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [timeStamp, setTimeStamp] = useState(new Date().getTime());

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const [confirm, setConfirm] = useState({
    "show": false,
    "text": "",
    "confirmBtnText": "",
    "onConfirm": () => {},
    "cancelBtnText": "",
    "onCancel": () => {}
  });

  const [confirmSend, setConfirmSend] = useState({
    "show": false,
    "text": "",
    "confirmBtnText": "",
    "onConfirm": () => {},
    "cancelBtnText": "",
    "onCancel": () => {},
    "showSend": false
  });

  const [search, setSearch] = useState({
    "doc_type_id": "",
    "keyword": ""
  });

  const handleSearchInputChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    });

    setPages({
      ...pages,
      "activePage": 1
    });
  }

  const initForm = {
    "doc_id": "",
    "doc_type_id": "",
    "doc_name": "",
    "attn": "",
    "position_id": "",
    "send_data": [],
    "preview_id": "",
    "doc_step_id": "",
    "school_usertype_id": ""
  };

  let activePage = new URLSearchParams(props.location.search).get("page");
  activePage = activePage === null ? 1 : parseInt(activePage);

  const [locationKeys, setLocationKeys] = useState([]);
  const [table, setTable] = useState([]);
  const [pages, setPages] = useState({
    "activePage": activePage
  });

  const handlePageChange = (e, page) => {
    history.push("?page=" + page);
  }

  const [form, setForm] = useState(initForm);

  const [docStatus, setDocStatus] = useState({
    "master": {},
    "detail": []
  });
  const [showStatus, setShowStatus] = useState(false);

  useEffect(() => {

  }, []);

  useEffect(() => {
    loadTable();
  }, [search, pages.activePage, paramId]);

  useEffect(() => {
    if(paramId !== null) {
      loadDetailId(paramId);
    }
    else {
      setShowForm(false);
    }
  }, [table]);

  useEffect(() => {
    setPages({
      ...pages,
      "activePage": activePage
    });

    setParamId(param_id);
    setParamDo(param_do);

    return history.listen(location => {
      if(history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
  
      if(history.action === "POP") {
        if(locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
        }
        else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  const loadTable = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/docall.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "keyword": search.keyword,
      "doc_type_id": 2,
      "page": pages.activePage
    })
    .then((res) => {
      let { status, data, page } = res.data;
      if(status == 1) {
        setPages(page);
        setTable(data.map((row) => {
          return {...row, loadingDetail: false, loadingStatus: false, loadingEdit: false, loadingDelete: false};
        }));
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadDetailId = (id) => {
    axios.post(process.env.REACT_APP_API_URL + "/school/doc.get", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "id": id,
      "type": "doc"
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setForm({
          ...data,
          "preview_id": data.media_id
        });
        setTimeStamp(new Date().getTime());

        setValidated(false);
        setShowForm(true);
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadDetail = (index) => {
    loadDetailId(table[index].id);
  }

  const loadStatus = (index) => {
    let id = table[index].id;

    axios.post(process.env.REACT_APP_API_URL + "/school/doc.step.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "id": id
    })
    .then((res) => {
      let { status, data_mas, data } = res.data;
      if(status == 1) {
        setDocStatus({
          "master": data_mas,
          "detail": data
        });
        setShowStatus(true);
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    })
    .finally(() => {
      table[index].loadingStatus = false;
    });
  }

  const showDoc = (e) => {
    window.open(docStatus.master.doc_url);
  }

  const detailData = (e, index) => {
    e.preventDefault();
    param_id = table[index].id;
    history.push("?id=" + param_id);
  }

  const statusData = (e, index) => {
    e.preventDefault();
    table[index].loadingStatus = true;
    loadStatus(index);
  }

  const handleMain = (e) => {
    param_id = null;
    param_do = null;
    history.push("?");
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            {(!showForm && paramId === null) &&
              <Card>
                <Card.Header>
                  <Row>
                    <Col md="6">
                      <Form inline>
                        <Form.Group>
                          <Form.Label className="mx-sm-2">ค้นหา</Form.Label>
                          <Form.Control type="text" name="keyword" value={search.keyword} placeholder="ค้นหา" onChange={handleSearchInputChange}></Form.Control>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th width="120"></th>
                        <th width="80">ลำดับ</th>
                        <th width="80">เลขที่</th>
                        <th width="150">วันที่สร้างเอกสาร</th>
                        <th width="150">ประเภทเอกสาร</th>
                        <th>ชื่อเอกสาร</th>
                        <th width="200">เอกสารประกอบ</th>
                        <th width="200"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {table.map((row, index) => (
                        <tr key={index}>
                          <td className="text-center"><Badge variant={row.doc_status_variant}>{row.doc_status_text}</Badge></td>
                          <td className="text-center">{row.no}</td>
                          <td className="text-center">{row.doc_no}</td>
                          <td className="text-center">{row.created_at}</td>
                          <td>{row.doc_type_name}</td>
                          <td>{row.doc_name}</td>
                          <td>
                            {row.other_files.map((_row, _index) => (
                              <div>
                                <a href={_row.url} target="_blank"><i class="far fa-file-alt"></i> {_row.name}</a>
                              </div>
                            ))}
                          </td>
                          <td className="text-center btn-manage-group">
                            <ButtonLoading type="link" icon="fas fa-search" className="btn-manage" loading={row.loadingDetail} onClick={e => detailData(e, index)}>ดูรายละเอียด</ButtonLoading>
                            <ButtonLoading type="link" icon="fas fa-list" className="btn-manage" loading={row.loadingStatus} onClick={e => statusData(e, index)}>สถานะ</ButtonLoading>
                            {/*<ButtonLoading type="link" icon="fas fa-pen" className="btn-manage" loading={row.loadingEdit} onClick={e => editData(e, index)}>แก้ไข</ButtonLoading>
                            <ButtonLoading type="link" icon="fas fa-times" className="btn-manage" loading={row.loadingDelete} onClick={e => deleteData(e, index)}>ลบ</ButtonLoading>*/}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Page {...pages} onClick={handlePageChange} />
                </Card.Body>
              </Card>
            }
            {showForm &&
              <Card>
                <Card.Body className="p-0">
                  <div>
                    <iframe src={process.env.REACT_APP_API_URL + "/school/doc.preview.pdf?id=" + form.preview_id + "&token=" + authenUser.token + "&t=" + timeStamp} style={{width: "100%", height: "72vh"}}></iframe>
                    <Form noValidate validated={validated} className="form-label-r">
                      <Form.Group as={Row} className="justify-content-center mt-3">
                        <Button type="button" variant="default" className="btn-fill mr-sm-2" onClick={handleMain}><i className="fas fa-arrow-left"></i> ย้อนกลับ</Button>
                      </Form.Group>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            }
          </Col>
        </Row>
      </Container>

      <Modal
        size="md"
        show={showStatus}
        onHide={() => setShowStatus(false)}
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title>สถานะเอกสาร</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="4">วันที่สร้างเอกสาร</Col>
            <Col sm="8">{docStatus.master.created_at}</Col>
          </Row>
          <Row>
            <Col sm="4">ประเภทเอกสาร</Col>
            <Col sm="8">{docStatus.master.type_name}</Col>
          </Row>
          <Row>
            <Col sm="4">ชื่อเอกสาร</Col>
            <Col sm="8">{docStatus.master.doc_name}</Col>
          </Row>
          
          <Table striped bordered hover className="mt-2">
            <thead>
              <tr>
                <th width="150"></th>
                <th>สถานะ</th>
              </tr>
            </thead>
            <tbody>
              {docStatus.detail.map((row, index) => (
                <tr key={index}>
                  <td>{row.updated_at}</td>
                  <td className={row.status_class}>{row.status_text}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button type="button" variant="primary" className="btn-fill mr-sm-2" onClick={showDoc}><i className="fas fa-file-alt"></i> ดูเอกสาร</Button>
          <Button type="button" variant="default" className="btn-fill" onClick={() => setShowStatus(false)}><i className="fas fa-times"></i> ปิด</Button>
        </Modal.Footer>
      </Modal>

      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
      {
        confirm.show && <SweetAlert
                        title={confirm.text}
                        btnSize="md"
                        onConfirm={confirm.onConfirm}
                        confirmBtnText={confirm.confirmBtnText}
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}}
                        showCancel={true}
                        onCancel={confirm.onCancel}
                        cancelBtnText={confirm.cancelBtnText}
                        cancelBtnBsStyle="default"
                        cancelBtnCssClass="btn-fill"
                        cancelBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default Doc;

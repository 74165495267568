import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import interact from "interactjs";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import * as pdfjsWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  Modal
} from "react-bootstrap";

import AuthenContext from "../../models/AuthenContext";
import ButtonLoading from "../../components/Controls/ButtonLoading";
import Page from "../../components/Controls/Page";
import Draw from "../../components/Controls/Draw";
import ImageLib from "../../libs/ImageLib";

function Doc(props) {
  const history = useHistory();

  const {authenUser, setIsLoginUser} = useContext(AuthenContext);

  let param_id = new URLSearchParams(props.location.search).get("id");
  const [paramId, setParamId] = useState(param_id);

  let param_do = new URLSearchParams(props.location.search).get("do");
  const [paramDo, setParamDo] = useState(param_do);

  const [showForm, setShowForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [step, setStep] = useState(1);
  const [timeStamp, setTimeStamp] = useState(new Date().getTime());
  const [tools, setTools] = useState([]);
  const [showTool, setShowTool] = useState({
    "position": false,
    "user": false,
    "send": false
  });
  const [isView, setIsView] = useState(false);

  const [showFormSign, setShowFormSign] = useState(false);
  const [validatedFormSign, setValidatedFormSign] = useState(false);

  const [titleFormText, setTitleFormText] = useState("");
  const [showFormText, setShowFormText] = useState(false);
  const [validatedFormText, setValidatedFormText] = useState(false);

  const [formChoice, setFormChoice] = useState({
    "choice_data": []
  });
  const [showFormChoice, setShowFormChoice] = useState(false);
  const [validatedFormChoice, setValidatedFormChoice] = useState(false);

  const [formMyChoice, setFormMyChoice] = useState({
    "title": "",
    "choice_data": [],
    "ref": null
  });
  const [showFormMyChoice, setShowFormMyChoice] = useState(false);
  const [validatedFormMyChoice, setValidatedFormMyChoice] = useState(false);

  const [showFormDisApprove, setShowFormDisApprove] = useState(false);
  const [validatedFormDisApprove, setValidatedFormDisApprove] = useState(false);

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const [confirm, setConfirm] = useState({
    "show": false,
    "text": "",
    "confirmBtnText": "",
    "onConfirm": () => {},
    "cancelBtnText": "",
    "onCancel": () => {}
  });

  const [confirmSend, setConfirmSend] = useState({
    "show": false,
    "text": "",
    "confirmBtnText": "",
    "onConfirm": () => {},
    "cancelBtnText": "",
    "onCancel": () => {},
    "showSend": false
  });

  const [search, setSearch] = useState({
    "doc_type_id": "",
    "keyword": ""
  });

  const handleSearchInputChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    });

    setPages({
      ...pages,
      "activePage": 1
    });
  }

  const initForm = {
    "doc_id": "",
    "doc_type_id": "",
    "doc_name": "",
    "attn": "",
    "position_id": "",
    "send_data": [],
    "preview_id": "",
    "doc_step_id": "",
    "curent_doc_step_id": "",
    "school_user_id": "",
    "is_route": false
  };

  let activePage = new URLSearchParams(props.location.search).get("page");
  activePage = activePage === null ? 1 : parseInt(activePage);

  const [locationKeys, setLocationKeys] = useState([]);
  const [table, setTable] = useState([]);
  const [pages, setPages] = useState({
    "activePage": activePage
  });

  const handlePageChange = (e, page) => {
    history.push("?page=" + page);
  }

  const [form, setForm] = useState(initForm);
  const [loadingSave1, setLoadingSave1] = useState(false);
  const [loadingSave2, setLoadingSave2] = useState(false);

  const initFormTool = {
    "position_id": null,
    "school_user_id": null
  };
  const [formTool, setFormTool] = useState(initFormTool);

  const initFormSign = {
    "type": 0,
    "data": null,
    "ref": null
  };
  const [formSign, setFormSign] = useState(initFormSign);
  const [clearDrawSign, setClearDrawSign] = useState(false);
  const canvasSign = useRef();
  const canvasPreviewSign = useRef();

  const initFormText = {
    "text": "",
    "ref": null
  };
  const [formText, setFormText] = useState(initFormText);

  const initFormDisApprove = {
    "remark": ""
  };
  const [formDisApprove, setFormDisApprove] = useState(initFormDisApprove);

  const canvasPdf = useRef();
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [docType, setDocType] = useState([]);
  const [position, setPosition] = useState([]);
  const [userType, setUserType] = useState([]);
  const [user, setUser] = useState([]);
  const [userFilter, setUserFilter] = useState([]);
  const [choice, setChoice] = useState([]);
  const [reader, setReader] = useState(new FileReader());

  const [docStatus, setDocStatus] = useState({
    "master": {},
    "detail": []
  });
  const [showStatus, setShowStatus] = useState(false);

  useEffect(() => {
    loadDocType();
    loadUser();
    loadUserType();
    loadChoice();
  }, []);

  useEffect(() => {
    loadTable();
  }, [search, pages.activePage, paramId]);

  useEffect(() => {
    if(paramId !== null) {
      if(paramDo == "edit") {
        loadEditId(paramId);
      }
      else {
        loadDetailId(paramId);
      }
    }
    else {
      setShowForm(false);
    }
  }, [table]);

  useEffect(() => {
    setPages({
      ...pages,
      "activePage": activePage
    });

    setParamId(param_id);
    setParamDo(param_do);

    return history.listen(location => {
      if(history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
  
      if(history.action === "POP") {
        if(locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
        }
        else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    if(showForm && form.doc_id) {
      loadTools();
      if(form.doc_file_url != undefined) {
        loadPdfUrl(form.doc_file_url);
      }
    }
  }, [showForm]);

  useEffect(() => {
    setUserFilter(user.filter(row => row.position_id == formTool.position_id));
  }, [formTool.position_id]);

  useEffect(() => {
    initInteract();
  }, [tools]);

  useEffect(() => {
    if(showFormSign) {
      let canvas = formSign.type == 0 ? canvasSign.current : canvasPreviewSign.current;
      let ctx = canvas.getContext("2d");
      let image = new Image();
      image.onload = () => {
        ctx.drawImage(image, 0, 0);
      };
      image.src = formSign.data;
    }
  }, [showFormSign]);

  const handleFormInputChange = (e) => {
    let key = e.target.name, value;
    if(e.target.type == "file") {
      value = e.target.files[0];
    }
    else if(e.target.type == "checkbox" && e.target.name.indexOf("[") != -1) {
      key = e.target.name.substr(0, e.target.name.indexOf("["));
      value = form[key];
      let index = form[key].indexOf(e.target.value);
      if(index == -1) {
        value.push(e.target.value);
      }
      else {
        value.splice(index, 1);
      }
    }
    else if(e.target.type == "checkbox") {
      value = e.target.checked;
    }
    else {
      value = e.target.value;
    }

    setForm({
      ...form,
      [key]: value
    });
  }

  const handleFormSignInputChange = (e) => {
    let key = e.target.name, value;
    if(e.target.type == "file") {
      value = e.target.files[0];
      if(key == "sign_file") {
        let canvas = canvasPreviewSign.current;
        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        let fr = new FileReader();
        fr.onload = () => {
          let image = new Image();
          image.onload = () => {
            ImageLib.drawImageScaleCanvas(image, ctx);
          };
          image.src = fr.result;
        };
        fr.readAsDataURL(value);
      }
    }
    else {
      value = e.target.value;
    }

    setFormSign({
      ...formSign,
      [key]: value
    });
  }

  const handleFormTextInputChange = (e) => {
    setFormText({
      ...formText,
      [e.target.name]: e.target.value
    });
  }

  const handleFormChoiceInputChange = (e) => {
    setFormChoice({
      ...formChoice,
      "choice_data": formChoice.choice_data.map((row, index) => {
        if(row.children) {
          row.children = row.children.map((row2, index2) => {
            return {...row2, checked: (e.target.value == index + "-" + index2 ? e.target.checked : (row2.checked !== undefined ? row2.checked : false))};
          });
        }
        return {...row, checked: (e.target.value == index ? e.target.checked : (row.checked !== undefined ? row.checked : false))};
      }
    )});
  }

  const handleFormMyChoiceInputChange = (e) => {
    setFormMyChoice({
      ...formMyChoice,
      "choice_data": formMyChoice.choice_data.map((row, index) => {
        if(row.children) {
          row.children = row.children.map((row2, index2) => {
            return {...row2, checked: (e.target.value == index + "-" + index2 ? e.target.checked : (row2.checked !== undefined ? row2.checked : false))};
          });
        }
        return {...row, checked: (e.target.value == index ? e.target.checked : (row.checked !== undefined ? row.checked : false))};
      }
    )});
  }

  const handleFormDisApproveInputChange = (e) => {
    setFormDisApprove({
      ...formDisApprove,
      [e.target.name]: e.target.value
    });
  }

  const handlePositionChange = (e) => {
    setFormTool({
      ...formTool,
      "position_id": e.target.value
    });
  }

  const loadTable = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/docin.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "keyword": search.keyword,
      "doc_type_id": search.doc_type_id,
      "page": pages.activePage
    })
    .then((res) => {
      let { status, data, page } = res.data;
      if(status == 1) {
        setPages(page);
        setTable(data.map((row) => {
          return {...row, loadingDetail: false, loadingStatus: false, loadingEdit: false, loadingDelete: false};
        }));
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadDetailId = (id) => {
    axios.post(process.env.REACT_APP_API_URL + "/school/doc.get", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "id": id,
      "type": "docin"
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        let is_view = !(data.doc_step_status == 0 || data.doc_step_status == 2);
        setIsView(is_view);

        if(is_view || !data.doc_data.is_add) {
          setForm({
            ...form,
            ...data,
            "preview_id": data.media_id
          });
          setTimeStamp(new Date().getTime());
          setStep(2);
        }
        else {
          setForm({
            ...form,
            ...data
          });
          setStep(1);
        }

        if(data.doc_data.approve_items) {
          data.doc_data.approve_items.map(item => {
            let row = choice.find(o => o.id == item.cid);

            if(row.choice_data) {
              row.choice_data.map((row2) => {
                if(row2.children) {
                  row2.children.map((row3) => {
                    return {...row3, checked: false};
                  });
                }
                return {...row2, checked: false};
              });
            }

            setFormChoice({
              ...formChoice,
              "choice_data": row.choice_data
            });
          });
        }

        setValidated(false);
        setFormTitle("รายละเอียดเอกสาร");
        setShowForm(true);
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadDetail = (index) => {
    loadDetailId(table[index].id);
  }

  const loadEditId = (id) => {
    axios.post(process.env.REACT_APP_API_URL + "/school/doc.get", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "id": id,
      "type": "docin-edit"
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        let is_view = false;
        setIsView(is_view);

        setForm(data);
        setStep(1);

        if(data.doc_data.approve_items) {
          data.doc_data.approve_items.map(item => {
            let row = choice.find(o => o.id == item.cid);

            if(row.choice_data) {
              row.choice_data.map((row2) => {
                if(row2.children) {
                  row2.children.map((row3) => {
                    return {...row3, checked: false};
                  });
                }
                return {...row2, checked: false};
              });
            }

            setFormChoice({
              ...formChoice,
              "choice_data": row.choice_data
            });
          });
        }

        setValidated(false);
        setFormTitle("รายละเอียดเอกสาร");
        setShowForm(true);
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadEdit = (index) => {
    loadEditId(table[index].id);
  }

  const loadStatus = (index) => {
    let id = table[index].id;

    axios.post(process.env.REACT_APP_API_URL + "/school/doc.step.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "id": id
    })
    .then((res) => {
      let { status, data_mas, data } = res.data;
      if(status == 1) {
        setDocStatus({
          "master": data_mas,
          "detail": data
        });
        setShowStatus(true);
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    })
    .finally(() => {
      table[index].loadingStatus = false;
    });
  }

  const showDoc = (e) => {
    window.open(docStatus.master.doc_url);
  }

  const detailData = (e, index) => {
    e.preventDefault();
    param_id = table[index].id;
    history.push("?id=" + param_id);
  }

  const statusData = (e, index) => {
    e.preventDefault();
    table[index].loadingStatus = true;
    loadStatus(index);
  }

  const editData = (e, index) => {
    e.preventDefault();
    param_do = "edit";
    param_id = table[index].id;
    history.push("?do=" + param_do + "&id=" + param_id);
  }

  const deleteData = (e, index) => {
    e.preventDefault();
    table[index].loadingDelete = true;
    setConfirm({
      "show": true,
      "text": `ต้องการลบ "${table[index].doc_name}" ใช่หรือไม่`,
      "confirmBtnText": "ลบ",
      "onConfirm": () => {
        let id = table[index].id;

        axios.post(process.env.REACT_APP_API_URL + "/school/doc.delete", {
          "api_key": process.env.REACT_APP_API_KEY,
          "token": authenUser.token,
          "id": id
        })
        .then((res) => {
          let { status, data } = res.data;
          if(status == 1) {
            loadTable();
          }
          else if(status == -1) {
            setIsLoginUser(false);
          }
          else {
            setAlert({
              "show": true,
              "text": res.data.msg
            });
          }
        })
        .catch((ex) => {
          console.log(ex);
          setAlert({
            "show": true,
            "text": process.env.REACT_APP_SERVER_NOT_CONNECT
          });
        })
        .finally(() => {
          table[index].loadingDelete = false;
        });

        setConfirm({"show": false});
      },
      "cancelBtnText": "ยกเลิก",
      "onCancel": () => {
        setConfirm({"show": false});
        table[index].loadingDelete = false;
      }
    });
  }

  const previewData = () => {
    setLoadingSave1(true);
    
    let doc_data = {
      "canvas": {
        "w": canvasPdf.current.clientWidth,
        "h": canvasPdf.current.clientHeight
      },
      "items": [],
      "approve_items": []
    };

    let items = document.getElementsByClassName("drop-pdf")[0].getElementsByClassName("drag-pdf-item");
    for(let i = 0; i < items.length; i++) {
      let item = items[i];
      let type = item.getAttribute("data-type");
      let x = item.offsetLeft + (parseFloat(item.getAttribute("data-x")) || 0);
      let y = item.offsetTop + (parseFloat(item.getAttribute("data-y")) || 0);
      
      //console.log(i, item.getAttribute("data-type"), item.getAttribute("data-id"), x, y, item.offsetWidth, item.offsetHeight, item.getAttribute("data-cid"));

      if(form.doc_data.approve_items) {
        doc_data.approve_items = form.doc_data.approve_items.map(item => {
          return { ...item, "approve_data": formChoice.choice_data };
        });
      }

      doc_data.items = [...doc_data.items, {
        "type": type,
        "group": item.getAttribute("data-group"),
        "id": item.getAttribute("data-id"),
        "cid": item.getAttribute("data-cid"),
        "x": x,
        "y": y,
        "w": item.offsetWidth,
        "h": item.offsetHeight,
        "text": item.textContent,
        "base64": item.getAttribute("data-sign"),
        "page": item.getAttribute("data-page")
      }];

      if(type == "choice") {
        doc_data.approve_items = [...doc_data.approve_items, {
          "type": type,
          "group": item.getAttribute("data-group"),
          "id": item.getAttribute("data-id"),
          "cid": item.getAttribute("data-cid"),
          "x": x,
          "y": y,
          "w": item.offsetWidth,
          "h": item.offsetHeight,
          "text": item.textContent,
          "base64": item.getAttribute("data-sign"),
          "page": item.getAttribute("data-page"),
          "approve_data": JSON.parse(item.getAttribute("data-choice"))
        }];
      }
    }

    let doc_file = form.doc_file == undefined ? {} : {
      "name": form.doc_file.name,
      "size": form.doc_file.size,
      "type": form.doc_file.type
    };

    axios.post(process.env.REACT_APP_API_URL + "/school/doc.set", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "do": "upload",
      "doc_id": form.doc_id,
      "doc_step_id": form.doc_step_id,
      "doc_file_base64": reader.result,
      "doc_file": doc_file,
      "doc_data": doc_data,
      "preview_id": form.preview_id,
      "approve_data": formChoice.choice_data
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setForm({
          ...form,
          "preview_id": data.id
        });
        setTimeStamp(new Date().getTime());
        setStep(2);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
    })
    .finally(() => {
      setLoadingSave1(false);
    });
  }

  const isSign = () => {
    let is_valid = false;

    let items = document.getElementsByClassName("drop-pdf")[0].getElementsByClassName("drag-pdf-item");
    for(let i = 0; i < items.length; i++) {
      let item = items[i];
      let type = item.getAttribute("data-type");

      if(type == "signature") {
        is_valid = true;
        break;
      }
    }

    return is_valid;
  }

  const formSubmit1 = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      e.stopPropagation();
    }
    else if(!isSign()) {
      setConfirm({
        "show": true,
        "text": "ท่านแน่ใจว่าต้องการส่งเอกสารฉบับนี้",
        "confirmBtnText": "ตกลง",
        "onConfirm": () => {
          setConfirm({"show": false});
          previewData();
        },
        "cancelBtnText": "ยกเลิก",
        "onCancel": () => {
          setConfirm({"show": false});
        }
      });
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidated(true);

    if(is_valid) {
      if(form.doc_data.approve_items) {
        setShowFormChoice(true);
      }
      else {
        previewData();
      }
    }
  }

  const formSubmit2 = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidated(true);

    if(is_valid) {
      setLoadingSave2(true);
      
      setConfirmSend({
        "show": true,
        "text": `ผู้เช็นลำดับถัดไป`,
        "confirmBtnText": "บันทึก/ส่งเอกสาร",
        "onConfirm": () => {},
        "cancelBtnText": "ยกเลิก",
        "onCancel": () => {
          setConfirmSend({"show": false});
          setLoadingSave2(false);
        },
        "showSend": showTool.send
      });
    }
  }

  const handleConfirmSend = (e) => {
    axios.post(process.env.REACT_APP_API_URL + "/school/doc.set", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "do": "save",
      ...form
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setStep(3);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
    })
    .finally(() => {
      setLoadingSave2(false);
    });

    setConfirmSend({"show": false});
  }

  const formSignSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidatedFormSign(true);

    if(is_valid) {
      formSign.ref.setAttribute("data-sign-type", formSign.type);
      if(formSign.type == 0) {
        formSign.ref.setAttribute("data-sign", canvasSign.current.toDataURL());
        setFormSign({
          ...formSign,
          "sign_data": canvasSign.current.toDataURL()
        });
      }
      else if(formSign.type == 1) {
        formSign.ref.setAttribute("data-sign", canvasPreviewSign.current.toDataURL());
      }
      formSign.ref.innerHTML = `<img src="${formSign.ref.getAttribute('data-sign')}" style="max-width:100%;max-height:100%;">`;
      
      setShowFormSign(false);
    }
  }

  const formTextSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidatedFormText(true);

    if(is_valid) {
      formText.ref.innerHTML = formText.text;
      setShowFormText(false);
    }
  }

  const formChoiceSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    //setValidatedFormChoice(true);

    if(is_valid) {
      setShowFormChoice(false);
      previewData();
    }
  }

  const formMyChoiceSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    if(is_valid) {
      let _choice_html = `<div class="flex-break">` + formMyChoice.title + `</div>`;
      if(formMyChoice.choice_data !== null) {
        formMyChoice.choice_data.map(_row => {
          _choice_html += `<div class="flex-break">` + (_row.children !== undefined ? `` : (_row.checked ? `<i class="far fa-check-square"></i> ` : `<i class="far fa-square"></i> `)) + _row.choice_name + `</div>`;
          if(_row.children !== undefined) {
            _row.children.map(_row2 => {
              _choice_html += `<div class="flex-break pl">` + (_row2.checked ? `<i class="far fa-check-square"></i> ` : `<i class="far fa-square"></i> `) + _row2.choice_name + `</div>`;
            });
          }
        });
      }
      formMyChoice.ref.innerHTML = _choice_html;
      formMyChoice.ref.setAttribute("data-choice", JSON.stringify(formMyChoice.choice_data));
      setShowFormMyChoice(false);
    }
  }

  const formDisApproveSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidatedFormDisApprove(true);

    if(is_valid) {
      axios.post(process.env.REACT_APP_API_URL + "/school/doc.set", {
        "api_key": process.env.REACT_APP_API_KEY,
        "token": authenUser.token,
        "do": "disapprove",
        "doc_id": form.doc_id,
        "doc_step_id": form.curent_doc_step_id,
        "doc_type_id": form.doc_type_id,
        ...formDisApprove
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          setStep(3);
        }
        else {
          setAlert({
            "show": true,
            "text": res.data.msg
          });
        }
      })
      .catch((ex) => {
        console.log(ex);
      });

      setShowFormDisApprove(false);
    }
  }

  const handleMain = (e) => {
    param_id = null;
    param_do = null;
    history.push("?");
  }

  const handleDisApprove = (e) => {
    setShowFormDisApprove(true);
  }

  const handleSuccess = (e) => {
    setConfirm({
      "show": true,
      "text": `ยืนยันการรับทราบ`,
      "confirmBtnText": "ตกลง",
      "onConfirm": () => {
        axios.post(process.env.REACT_APP_API_URL + "/school/doc.set", {
          "api_key": process.env.REACT_APP_API_KEY,
          "token": authenUser.token,
          "do": "success",
          "doc_id": form.doc_id,
          "doc_step_id": form.curent_doc_step_id
        })
        .then((res) => {
          let { status, data } = res.data;
          if(status == 1) {
            setStep(3);
          }
          else if(status == -1) {
            setIsLoginUser(false);
          }
          else {
            setAlert({
              "show": true,
              "text": res.data.msg
            });
          }
        })
        .catch((ex) => {
          console.log(ex);
          setAlert({
            "show": true,
            "text": process.env.REACT_APP_SERVER_NOT_CONNECT
          });
        });

        setConfirm({"show": false});
      },
      "cancelBtnText": "ยกเลิก",
      "onCancel": () => {
        setConfirm({"show": false});
      }
    });
  }

  const loadDocType = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/doctype.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "is_user": true,
      "order": "seq",
      "limit": 1000
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setDocType(data);
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadPosition = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/position.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "order": "position_id",
      "limit": 1000
    })
    .then((res) => {
      let { status, data, _default } = res.data;
      if(status == 1) {
        setPosition(data);
        setFormTool({
          ...formTool,
          "position_id": _default.id
        });
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadUserType = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/usertype.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "is_user": true,
      "order": "school_usertype_id",
      "limit": 1000
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setUserType(data);
        setForm({
          ...form,
          "school_usertype_id": data[0].id
        });
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadUser = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/user.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "is_user": true,
      "order": "school_user_id",
      "limit": 1000
    })
    .then((res) => {
      let { status, data, _default } = res.data;
      if(status == 1) {
        setUser(data);
        formTool.school_user_id = _default.id;
        loadPosition();
        setForm({
          ...form,
          "school_user_id": data[0].id
        });
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadChoice = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/choice.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "order": "choice_name",
      "limit": 1000
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setChoice(data);
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadTools = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/doc.tools", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "type": form.doc_type_id,
      "step": form.step,
      "step_type": form.step_type
    })
    .then((res) => {
      let { status, data, show } = res.data;
      if(status == 1) {
        setTools(data);
        setShowTool(show);
        if(form.doc_data !== null) {
          plotItem(form.doc_data);
        }
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const [loadingTask, setLoadingTask] = useState(null);
  const [pageNumPdf, setPageNumPdf] = useState(1);
  const [pageRenderingPdf, setPageRenderingPdf] = useState(false);
  const [pageNumPendingPdf, setPageNumPendingPdf] = useState(null);
  const [pageTotalPdf, setPageTotalPdf] = useState(1);

  useEffect(() => {
    if(loadingTask !== null) {
      renderPdfPage(pageNumPdf);
      plotItemPage(pageNumPdf);
    }
  }, [loadingTask, pageNumPdf]);

  const loadPdf = () => {console.log("loadPdf:", form.doc_file);
    reader.readAsDataURL(form.doc_file);
    reader.onload = function() {
      setLoadingTask(pdfjsLib.getDocument({"data": window.atob(reader.result.split(",")[1])}));
      setPageNumPdf(1);
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  }

  const loadPdfUrl = () => {
    setLoadingTask(pdfjsLib.getDocument(form.doc_file_url));
    setPageNumPdf(1);
  }

  const renderPdfPage = (page) => {
    setPageRenderingPdf(true);

    const canvas = canvasPdf.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);

    loadingTask.promise.then(pdf => {
      setPageTotalPdf(pdf.numPages);

      pdf.getPage(page).then(page => {
        const viewport = page.getViewport({scale: 1.5});
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport
        };
        page.render(renderContext).promise.then(function() {
          setPageRenderingPdf(false);
          if(pageNumPendingPdf !== null) {
            setPageNumPdf(pageNumPendingPdf);
            setPageNumPendingPdf(null);
          }
        });
      });
    }, reason => {
      console.log(reason);
    });
  }

  const queueRenderPdfPage = (page) => {
    if(pageRenderingPdf) {
      setPageNumPendingPdf(page);
    }
    else {
      setPageNumPdf(page);
    }
  }

  const prevPdfPage = (e) => {
    if(pageNumPdf > 1) {
      let page = pageNumPdf - 1;
      queueRenderPdfPage(page);
    }
  }

  const nextPdfPage = (e) => {
    if(pageNumPdf < pageTotalPdf) {
      let page = pageNumPdf + 1;
      queueRenderPdfPage(page);
    }
  }

  const plotItemPage = (page) => {
    let items = document.getElementsByClassName("drop-pdf")[0].getElementsByClassName("drag-pdf-item");
    for(let i = 0; i < items.length; i++) {
      let item = items[i];
      item.classList.add("hide");
    }
    for(let i = 0; i < items.length; i++) {
      let item = items[i];
      let _page = item.getAttribute("data-page");
      if(_page == page) {
        item.classList.remove("hide");
      }
    }
  }

  const plotItem = (entries) => {
    for(let i = 0; i < entries.items.length; i++) {
      let data = entries.items[i];

      let items = document.getElementsByClassName('drag-pdf')[0].getElementsByClassName('draggable');
      let item;
      for(let j = 0; j < items.length; j++) {
        item = items[j];
        if(item.getAttribute('data-type') == data.type /*&& getAttribute('data-group') == data.group*/) {
          break;
        }
      }

      let drop_pdf = document.getElementsByClassName('drop-pdf')[0];
      let clone = item.cloneNode(true);
      clone.setAttribute('clonable', 'false');
      clone.setAttribute('data-in', 'false');
      clone.setAttribute('data-group', data.group);
      clone.setAttribute('data-id', data.id);
      clone.setAttribute('data-cid', data.cid);
      clone.setAttribute('data-sign', data.base64);
      clone.setAttribute('data-page', data.page);

      clone.style.position = "absolute";
      clone.style.left = data.x + "px";
      clone.style.top = data.y + "px";
      clone.style.width = data.w + "px";
      clone.style.height = data.h + "px";
      clone.classList.add('drag-pdf-item');
      clone.classList.add(data.type);
      clone.innerHTML = data.text;

      if(data.type == "signature") {
        clone.innerHTML = `<img src="${clone.getAttribute('data-sign')}" style="max-width:100%;max-height:100%;">`;
      }

      if(data.type == "choice") {
        let approve_data = [];
        if(entries.default_approve_items !== null) {
          entries.default_approve_items.map(_row => {
            if(_row.id == data.id) {
              approve_data = _row.approve_data;
            }
          });
        }

        let sign_choice_id = document.getElementById('sign_choice_id');
        let cindex = 0;
        for(let j = 0; j < sign_choice_id.options.length; j++) {
          if(sign_choice_id.options[j].value == data.cid) {
            cindex = j;
          }
        }

        let _choice = JSON.parse(sign_choice_id.options[cindex].getAttribute('data-choice'));
        let _choice_html = `<div class="flex-break">` + sign_choice_id.options[cindex].text + `</div>`;
        if(_choice !== null) {
          _choice.map((_row, _index) => {
            //_choice_html += `<div class="flex-break">` + (_row.children !== undefined ? `` : `<i class="far fa-square"></i> `) + _row.choice_name + `</div>`;
            _choice_html += `<div class="flex-break">` + (_row.children !== undefined ? `` : (approve_data[_index].checked ? `<i class="far fa-check-square"></i> ` : `<i class="far fa-square"></i> `)) + _row.choice_name + `</div>`;
            _row.checked = approve_data[_index].checked;
            if(_row.children !== undefined) {
              _row.children.map((_row2, _index2) => {
                //_choice_html += `<div class="flex-break pl"><i class="far fa-square"></i> ` + _row2.choice_name + `</div>`;
                _choice_html += `<div class="flex-break pl">` + (approve_data[_index].children[_index2].checked ? `<i class="far fa-check-square"></i> ` : `<i class="far fa-square"></i> `) + _row2.choice_name + `</div>`;
                _row2.checked = approve_data[_index].children[_index2].checked;
              });
            }
          });
        }
        clone.innerHTML = _choice_html;
        //clone.style.width = 200 + 'px';
        clone.setAttribute('data-title', sign_choice_id.options[cindex].text);
        clone.setAttribute('data-choice', JSON.stringify(_choice));
      }

      drop_pdf.appendChild(clone);
    }

    plotItemPage(pageNumPdf);
  }
  
  const drag_types1 = [
    { "name": "ตัวอักษร", "type": "text", "icon": "fas fa-font" },
    { "name": "ลายเซ็นตนเอง", "type": "signature", "icon": "fas fa-pencil-alt" },
    { "name": "เลขรัน", "type": "runno", "icon": "fas fa-list-ol" },
    { "name": "ปี", "type": "year", "icon": "far fa-calendar-alt" },
    { "name": "วันที่", "type": "date", "icon": "far fa-calendar" }
  ];

  const drag_types2 = [
    { "name": "ตัวอักษร", "type": "text", "icon": "fas fa-font" },
    { "name": "ลายเซ็น", "type": "signature", "icon": "fas fa-pencil-alt" },
    { "name": "ชื่อนามสกุล", "type": "name", "icon": "fab fa-adn" },
    { "name": "ตำแหน่ง", "type": "position", "icon": "fas fa-crown" },
    { "name": "วันที่", "type": "date", "icon": "far fa-calendar" },
    { "name": "ความคิดเห็น", "type": "comment", "icon": "far fa-comment" },
    { "name": "ตัวเลือกการอนุมัติ", "type": "choice", "icon": "far fa-check-square" },
  ];

  const initInteract = () => {
    interact('.draggable').draggable({
      manualStart: true,
      inertia: true,
      listeners: {
        start(event) {
          event.currentTarget.classList.add('z-top');
          document.getElementsByClassName('sidebar-content')[0].classList.add('draging');
        },
        move(event) {
          var target = event.target;   
          var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
          //console.log("draggable", x, y);
          event.target.style.transform = `translate(${x}px, ${y}px)`;
  
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        },
        end(event) {
          let type = event.target.getAttribute("data-type");
          if(event.target.getAttribute("data-in") == "false") {
            event.target.remove();
          }
          else if(type == "signature" && event.target.getAttribute("data-drop") == "false") {
            event.target.setAttribute("data-drop", "true");
            setFormSign({
              "type": 0,
              "data": event.target.getAttribute("data-sign"),
              "ref": event.target
            });
            setValidatedFormSign(false);
            setShowFormSign(true);
          }
          else if(["name", "comment", "text"].indexOf(type) != -1 && event.target.getAttribute("data-drop") == "false") {
            event.target.setAttribute("data-drop", "true");
            setTitleFormText(type == "name" ? " ชื่อนามสกุล" : (type == "comment" ? "ความคิดเห็น" : "ตัวอักษร"));
            setFormText({
              ...formText,
              "text": event.target.textContent,
              "ref": event.target
            });
            setValidatedFormText(false);
            setShowFormText(true);
          }
        }
      }
    }).on('move', event => {
      var interaction = event.interaction;
      //console.log("move", interaction.pointerIsDown, !interaction.interacting(), event.currentTarget.getAttribute('clonable'));
      //console.log("move", interaction);
      let sign_position_id = document.getElementById('sign_position_id');
      let sign_user_id = document.getElementById('sign_user_id');
      let sign_choice_id = document.getElementById('sign_choice_id');
      let is_focus = (sign_position_id === document.activeElement || sign_user_id === document.activeElement || sign_choice_id === document.activeElement);

      if (interaction.pointerIsDown && !interaction.interacting() && event.currentTarget.getAttribute('clonable') != 'false' && !is_focus) {
        var original = event.currentTarget;
        var clone = event.currentTarget.cloneNode(true);
        console.log("clone", clone);
        var drop_pdf = document.getElementsByClassName('drop-pdf')[0];
        var x = clone.offsetLeft;
        var y = clone.offsetTop;
        clone.setAttribute('clonable', 'false');
        clone.setAttribute('data-in', 'false');
        clone.setAttribute('data-drop', 'false');
        
        if(["runno", "date", "year", "stamp"].indexOf(clone.getAttribute('data-type')) != -1) {
          clone.innerHTML = clone.getAttribute('data-text');
        }
        if(clone.getAttribute('data-type') == 'date') {
          clone.style.width = 150 + 'px';
        }
        if(clone.getAttribute('data-type') == 'stamp') {
          clone.style.width = 220 + 'px';
        }

        if(clone.getAttribute('data-group') == '2') {
          clone.setAttribute('data-id', sign_user_id.value);
          if(sign_choice_id !== null) clone.setAttribute('data-cid', sign_choice_id.value);
          clone.innerHTML = clone.innerHTML + '&nbsp;' + sign_user_id.options[sign_user_id.selectedIndex].text;
          if(clone.getAttribute('data-type') == 'name') {
            clone.innerHTML = sign_user_id.options[sign_user_id.selectedIndex].text;
            clone.style.width = 200 + 'px';
          }
          if(clone.getAttribute('data-type') == 'position') {
            clone.innerHTML = sign_position_id.options[sign_position_id.selectedIndex].text;
            clone.style.width = 200 + 'px';
          }
          if(clone.getAttribute('data-type') == 'choice') {
            let _choice = JSON.parse(sign_choice_id.options[sign_choice_id.selectedIndex].getAttribute('data-choice'));
            let _choice_html = `<div class="flex-break">` + sign_choice_id.options[sign_choice_id.selectedIndex].text + `</div>`;
            if(_choice !== null) {
              _choice.map(_row => {
                _choice_html += `<div class="flex-break">` + (_row.children !== undefined ? `` : `<i class="far fa-square"></i> `) + _row.choice_name + `</div>`;
                if(_row.children !== undefined) {
                  _row.children.map(_row2 => {
                    _choice_html += `<div class="flex-break pl"><i class="far fa-square"></i> ` + _row2.choice_name + `</div>`;
                  });
                }
              });
            }
            clone.innerHTML = _choice_html;
            clone.style.width = 200 + 'px';
            clone.setAttribute('data-title', sign_choice_id.options[sign_choice_id.selectedIndex].text);
            clone.setAttribute('data-choice', sign_choice_id.options[sign_choice_id.selectedIndex].getAttribute('data-choice'));
          }
          if(clone.getAttribute('data-type') == 'signature') {
            clone.setAttribute('data-sign', sign_user_id.options[sign_user_id.selectedIndex].getAttribute('data-sign'));
            clone.innerHTML = `<img src="${clone.getAttribute('data-sign')}" style="max-width:100%;max-height:100%;">`;
            clone.style.width = 200 + 'px';
          }
        }

        let page_num_pdf = document.getElementById('page_num_pdf');
        clone.setAttribute('data-page', page_num_pdf.value);

        clone.style.position = "absolute";
        //clone.style.left = original.offsetLeft + "px";
        //clone.style.top = original.offsetTop + "px";
        console.log('drop_pdf', drop_pdf);
        clone.style.left = (original.offsetLeft + drop_pdf.offsetWidth + (original.parentElement.offsetLeft - (drop_pdf.offsetLeft + drop_pdf.offsetWidth))) + "px";
        clone.style.top = (original.offsetTop - 20) + "px";
        clone.classList.add('drag-pdf-item');
        clone.classList.add(original.getAttribute('data-type'));
        //original.parentElement.appendChild(clone);
        drop_pdf.appendChild(clone);
        interaction.start({ name: 'drag' }, event.interactable, clone);
      }
      else if(interaction.pointerIsDown && !interaction.interacting() && event.currentTarget.getAttribute('clonable') == 'false' && !is_focus) {
        //console.log(event.currentTarget.offsetWidth, event.offsetX, event.layerX, event.currentTarget.offsetHeight, event.offsetY, event.layerY);
        if(event.currentTarget.offsetWidth - 8 > event.offsetX && event.currentTarget.offsetHeight - 8 > event.offsetY) {
          interaction.start({ name: 'drag' }, event.interactable, event.currentTarget);
        }
      }
    }).on('tap', event => {
      let type = event.currentTarget.getAttribute("data-type");
      if(type == "signature") {
        let sign_type = event.currentTarget.getAttribute("data-sign-type");
        setFormSign({
          "type": sign_type == undefined ? 0 : sign_type,
          "data": event.currentTarget.getAttribute("data-sign"),
          "ref": event.currentTarget
        });
        setValidatedFormSign(false);
        setShowFormSign(true);
      }
      else if(["name", "comment", "text"].indexOf(type) != -1) {
        setTitleFormText(type == "name" ? " ชื่อนามสกุล" : (type == "comment" ? "ความคิดเห็น" : "ตัวอักษร"));
        setFormText({
          ...formText,
          "text": event.currentTarget.textContent,
          "ref": event.currentTarget
        });
        setValidatedFormText(false);
        setShowFormText(true);
      }
      else if(type == "choice") {
        let _choice = JSON.parse(event.currentTarget.getAttribute('data-choice'));
        setFormMyChoice({
          ...formMyChoice,
          "title": event.currentTarget.getAttribute('data-title'),
          "choice_data": _choice,
          "ref": event.currentTarget
        });
        setValidatedFormMyChoice(false);
        setShowFormMyChoice(true);
      }
    }).resizable({
      // resize from all edges and corners
      edges: { top: false, left: false, bottom: true, right: true },
  
      listeners: {
        move (event) {
          var target = event.target
          var x = (parseFloat(target.getAttribute('data-x')) || 0)
          var y = (parseFloat(target.getAttribute('data-y')) || 0)
  
          // update the element's style
          console.log("resizable - w", event.rect.width, target.style.width);
          target.style.width = event.rect.width + 'px'
          target.style.height = event.rect.height + 'px'
  
          // translate when resizing from top or left edges
          x += event.deltaRect.left
          y += event.deltaRect.top
          console.log("resizable", x, y);
          target.style.transform = 'translate(' + x + 'px,' + y + 'px)'
  
          target.setAttribute('data-x', x)
          target.setAttribute('data-y', y)
        }
      },
      modifiers: [
        // keep the edges inside the parent
        interact.modifiers.restrictEdges({
          outer: 'parent'
        }),
  
        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 100, height: 30 }
        })
      ],
      restrict: {
        restriction: ".drop-pdf"
      },
      inertia: true,
      margin: 5
    });
  
    interact('.dropzone').dropzone({
      accept: '.draggable',
      overlap: 1,
      ondropactivate: event => {
        //console.log("ondropactivate", event);
      },
      ondragenter: event => {
        event.relatedTarget.setAttribute('data-in', 'true');
      },
      ondragleave: event => {
        event.relatedTarget.setAttribute('data-in', 'false');
      },
      ondrop: event => {
        //console.log("ondrop", event);
        event.relatedTarget.classList.remove('z-top');
        document.getElementsByClassName('sidebar-content')[0].classList.remove('draging');
      },
      ondropdeactivate: event => {
        
      }
    });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            {(!showForm && paramId === null) &&
              <Card>
                <Card.Header>
                  <Row>
                    <Col md="6">
                      <Form inline>
                        <Form.Group>
                          <Form.Label className="mr-sm-2">ประเภทเอกสาร</Form.Label>
                          <Form.Control as="select" name="doc_type_id" value={search.doc_type_id} onChange={handleSearchInputChange} required>
                            <option value="">- ทั้งหมด -</option>
                            {docType.map((row, index) => <option value={row.id} key={index}>{row.doc_type_name}</option>)}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="mx-sm-2">ค้นหา</Form.Label>
                          <Form.Control type="text" name="keyword" value={search.keyword} placeholder="ค้นหา" onChange={handleSearchInputChange}></Form.Control>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th width="120"></th>
                        <th width="80">ลำดับ</th>
                        <th width="80">เลขที่</th>
                        <th width="150">วันที่สร้างเอกสาร</th>
                        <th width="150">ประเภทเอกสาร</th>
                        <th>ชื่อเอกสาร</th>
                        <th width="200">เอกสารประกอบ</th>
                        <th width="200"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {table.map((row, index) => (
                        <tr key={index}>
                          <td className="text-center"><Badge variant={row.doc_status_variant}>{row.doc_status_text}</Badge></td>
                          <td className="text-center">{row.no}</td>
                          <td className="text-center">{row.doc_no}</td>
                          <td className="text-center">{row.created_at}</td>
                          <td>{row.doc_type_name}</td>
                          <td>{row.doc_name}</td>
                          <td>
                            {row.other_files.map((_row, _index) => (
                              <div>
                                <a href={_row.url} target="_blank"><i class="far fa-file-alt"></i> {_row.name}</a>
                              </div>
                            ))}
                          </td>
                          <td className="text-center btn-manage-group">
                            <ButtonLoading type="link" icon="fas fa-search" className="btn-manage" loading={row.loadingDetail} onClick={e => detailData(e, index)}>ดูรายละเอียด</ButtonLoading>
                            <ButtonLoading type="link" icon="fas fa-list" className="btn-manage" loading={row.loadingStatus} onClick={e => statusData(e, index)}>สถานะ</ButtonLoading>
                            {/*<ButtonLoading type="link" icon="fas fa-pen" className="btn-manage" loading={row.loadingEdit} onClick={e => editData(e, index)}>แก้ไข</ButtonLoading>
                            <ButtonLoading type="link" icon="fas fa-times" className="btn-manage" loading={row.loadingDelete} onClick={e => deleteData(e, index)}>ลบ</ButtonLoading>*/}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Page {...pages} onClick={handlePageChange} />
                </Card.Body>
              </Card>
            }
            {showForm &&
              <Card>
                {!isView && <Card.Header className="p-0">
                  <ul className="steps">
                    <li className={"step col-3" + (step >= 1 ? " active" : "")}>
                      <i className={"step-icon fas " + (step >= 1 ? "fa-check-circle" : "fa-dot-circle")}></i>
                      <span className="step-content">ตำแหน่งลายเซ็น</span>
                    </li>
                    <li className={"step col-3" + (step >= 2 ? " active" : "")}>
                      <i className={"step-icon fas " + (step >= 2 ? "fa-check-circle" : "fa-dot-circle")}></i>
                      <span className="step-content">ตรวจสอบ</span>
                    </li>
                    <li className={"step col-3" + (step >= 3 ? " active" : "")}>
                      <i className={"step-icon fas " + (step >= 3 ? "fa-check-circle" : "fa-dot-circle")}></i>
                      <span className="step-content">เสร็จสิ้น</span>
                    </li>
                  </ul>
                </Card.Header>}
                <Card.Body className="p-0">
                  <div className={step == 1 ? "" : "d-none"}>
                    <div className="sidebar-r">
                      <div className="sidebar-content">
                        <div className="dropzone drop-pdf">
                          <canvas ref={canvasPdf} className="box-paper" width="892" height="1262"></canvas>
                        </div>
                        <div className="text-center">
                          <Button type="button" variant="default" className="btn-fill mr-sm-2" onClick={prevPdfPage}><i className="fas fa-arrow-left"></i></Button>
                          <span className="page-no">หน้า &nbsp; {pageNumPdf} / {pageTotalPdf}</span>
                          <Button type="button" variant="default" className="btn-fill ml-sm-2" onClick={nextPdfPage}><i className="fas fa-arrow-right"></i></Button>
                          <Form.Control type="hidden" id="page_num_pdf" value={pageNumPdf}></Form.Control>
                        </div>
                        <Form noValidate validated={validated} onSubmit={formSubmit1} className="form-label-r">
                          <Form.Group as={Row} className="justify-content-center mt-3">
                            <Button type="button" variant="default" className="btn-fill mr-sm-2" onClick={handleMain}><i className="fas fa-arrow-left"></i> ย้อนกลับ</Button>
                            <Button type="button" variant="danger" className="btn-fill mr-sm-2" onClick={handleDisApprove}><i className="fas fa-ban"></i> ดำเนินการแก้ไข</Button>
                            <ButtonLoading type="submit" icon="fas fa-arrow-right" className="btn btn-primary btn-fill mr-sm-2" loading={loadingSave1}>เสนอต่อ</ButtonLoading>
                            <Button type="button" variant="success" className="btn-fill" onClick={handleSuccess}><i className="fas fa-check"></i> รับทราบ</Button>
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="sidebar-wrap drag-pdf">
                        <Form.Control as="select" id="sign_position_id" name="position_id" value={formTool.position_id} onChange={handlePositionChange} className={"mb-2" + (showTool.position ? "" : " d-none")}>
                          {position.map((row, index) => <option value={row.id} key={index}>{row.position_name}</option>)}
                        </Form.Control>
                        <Form.Control as="select" id="sign_user_id" name="school_user_id" defaultValue={formTool.school_user_id} className={"mb-2" + (showTool.user ? "" : " d-none")}>
                          {userFilter.map((row, index) => <option value={row.id} key={index} data-sign={row.sign_data}>{row.fullname}</option>)}
                        </Form.Control>
                        {tools.map((row, index) =>
                          <>
                            <div className="draggable mb-2" data-type={row.type} data-group={row.group} data-text={row.text} key={index}><i className={row.icon}></i> {row.name}</div>
                            {row.type == "choice" && <Form.Control as="select" id="sign_choice_id" name="school_choice_id" className="mb-2">
                              {choice.map((row2, index2) => <option value={row2.id} key={index2} data-choice={JSON.stringify(row2.choice_data)}>{row2.choice_name}</option>)}
                            </Form.Control>}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={step == 2 ? "" : "d-none"}>
                    <iframe src={process.env.REACT_APP_API_URL + "/school/doc.preview.pdf?id=" + form.preview_id + "&token=" + authenUser.token + "&t=" + timeStamp} style={{width: "100%", height: "72vh"}}></iframe>
                    <Form noValidate validated={validated} onSubmit={formSubmit2} className="form-label-r">
                      <Form.Group as={Row} className="justify-content-center mt-3">
                        {(isView || !form.doc_data.is_add) && <Button type="button" variant="default" className="btn-fill mr-sm-2" onClick={handleMain}><i className="fas fa-arrow-left"></i> ย้อนกลับ</Button>}
                        {(!isView && form.doc_data.is_add) && <Button type="button" variant="default" className="btn-fill mr-sm-2" onClick={() => setStep(1)}><i className="fas fa-arrow-left"></i> แก้ไข</Button>}
                        {!isView && <ButtonLoading type="submit" icon="fas fa-check" className="btn btn-primary btn-fill" loading={loadingSave2}>{form.doc_data.is_add ? "เสร็จสิ้น" : "รับทราบ"}</ButtonLoading>}
                      </Form.Group>
                    </Form>
                  </div>
                  <div className={step == 3 ? "" : "d-none"}>
                    <div className="text-center my-5">
                      <i className="far fa-check-circle text-success i-xxl"></i>
                      <div className="my-3">
                        การเพิ่มเอกสารเสร็จสิ้น<br/>
                        ระบบได้ทำการส่งเอกสารของท่านไปยังผู้ที่เกี่ยวข้องแล้ว
                      </div>
                      <Button type="button" variant="primary" className="btn-fill" onClick={handleMain}><i className="fas fa-arrow-left"></i> กลับหน้าหลัก</Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            }
          </Col>
        </Row>
      </Container>

      <Modal
        size="md"
        backdrop="static"
        show={showFormSign}
        onHide={() => setShowFormSign(false)}
      >
        <Form noValidate validated={validatedFormSign} onSubmit={formSignSubmit}>
          <Modal.Header className="justify-content-center">
            <Modal.Title>ลายเซ็น</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Col sm="12">
                <Row className="justify-content mt-1">
                  <Col sm="auto">
                    <Form.Check type="radio" id="sign_type_0" name="type" value="0" label="เซ็นใหม่" checked={formSign.type == 0} onChange={handleFormSignInputChange} custom />
                  </Col>
                  <Col sm="auto">
                    <Form.Check type="radio" id="sign_type_1" name="type" value="1" label="แนบลายเซ็น" checked={formSign.type == 1} onChange={handleFormSignInputChange} custom />
                  </Col>
                </Row>
                <Row>
                  <div className={"w-100" + (formSign.type != 0 ? " d-none" : "")}>
                    <div className="box-sign-wrap">
                      <div className="box-sign-mark">เซ็นลายเซ็นที่นี่</div>
                      <Draw id="draw-sign" width="480" height="200" ref={canvasSign} clearDraw={clearDrawSign} setClearDraw={setClearDrawSign} color="#00f" className="box-sign"></Draw>
                    </div>
                    <Button type="button" variant="danger" className="btn-fill" onClick={() => setClearDrawSign(true)}><i className="fas fa-eraser"></i> ล้าง</Button>
                  </div>
                  <div className={"w-100" + (formSign.type != 1 ? " d-none" : "")}>
                    <div className="box-sign-wrap">
                      <div className="box-sign-mark"><i className="far fa-image box-sign-icon"></i></div>
                      <canvas id="preview-sign" width="480" height="200" ref={canvasPreviewSign} className="box-sign"></canvas>
                    </div>
                  </div>
                </Row>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            {formSign.type == 1 &&
              <label className="btn btn-primary btn-fill mr-sm-2">
                <i className="fas fa-paperclip"></i> แนบลายเซ็น
                <Form.File.Input name="sign_file" onChange={handleFormSignInputChange} accept=".jpg,.jpeg,.png,.gif" className="d-none" />
              </label>
            }
            <Button type="submit" variant="success" className="btn-fill mr-sm-2"><i className="fas fa-check"></i> ตกลง</Button>
            <Button type="button" variant="default" className="btn-fill" onClick={() => setShowFormSign(false)}><i className="fas fa-times"></i> ปิด</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="lg"
        backdrop="static"
        show={showFormText}
        onHide={() => setShowFormText(false)}
      >
        <Form noValidate validated={validatedFormText} onSubmit={formTextSubmit}>
          <Modal.Header className="justify-content-center">
            <Modal.Title>{titleFormText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column sm="2">{titleFormText}</Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="text" value={formText.text} onChange={handleFormTextInputChange} required></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน {titleFormText}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button type="submit" variant="primary" className="btn-fill mr-sm-2"><i className="fas fa-check"></i> ตกลง</Button>
            <Button type="button" variant="default" className="btn-fill" onClick={() => setShowFormText(false)}><i className="fas fa-times"></i> ปิด</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="md"
        backdrop="static"
        show={showFormChoice}
        onHide={() => setShowFormChoice(false)}
      >
        <Form noValidate validated={validatedFormChoice} onSubmit={formChoiceSubmit}>
          <Modal.Header className="justify-content-center">
            <Modal.Title>ตัวเลือกการอนุมัติ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {formChoice.choice_data.map((row, index) =>
              <>
                <Form.Group as={Row}>
                  <Col sm="12">
                    <Form.Check type="checkbox" id={"choice-" + index} name={"choice-" + index} value={index} label={row.choice_name} checked={row.checked} onChange={handleFormChoiceInputChange} key={index} custom />
                  </Col>
                </Form.Group>
                {row.children && row.children.map((row2, index2) =>
                  <Form.Group as={Row}>
                    <Col sm={{ span: 11, offset: 1 }}>
                      <Form.Check type="checkbox" id={"choice-" + index + "-" + index2} name={"choice-" + index + "-" + index2} value={index + "-" + index2} label={row2.choice_name} checked={row2.checked} onChange={handleFormChoiceInputChange} key={index + "-" + index2} custom />
                    </Col>
                  </Form.Group>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button type="submit" variant="primary" className="btn-fill mr-sm-2"><i className="fas fa-check"></i> ตกลง</Button>
            <Button type="button" variant="default" className="btn-fill" onClick={() => setShowFormChoice(false)}><i className="fas fa-times"></i> ปิด</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="md"
        backdrop="static"
        show={showFormMyChoice}
        onHide={() => setShowFormMyChoice(false)}
      >
        <Form noValidate validated={validatedFormMyChoice} onSubmit={formMyChoiceSubmit}>
          <Modal.Header className="justify-content-center">
            <Modal.Title>ตัวเลือกการอนุมัติ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Col sm="12">{formMyChoice.title}</Col>
            </Form.Group>
            {formMyChoice.choice_data.map((row, index) =>
              <>
                <Form.Group as={Row}>
                  <Col sm="12">
                    {row.children
                    ? <>{row.choice_name}</>
                    : <Form.Check type="checkbox" id={"choice-" + index} name={"choice-" + index} value={index} label={row.choice_name} checked={row.checked} onChange={handleFormMyChoiceInputChange} key={index} custom />}
                  </Col>
                </Form.Group>
                {row.children && row.children.map((row2, index2) =>
                  <Form.Group as={Row}>
                    <Col sm={{ span: 11, offset: 1 }}>
                      <Form.Check type="checkbox" id={"choice-" + index + "-" + index2} name={"choice-" + index + "-" + index2} value={index + "-" + index2} label={row2.choice_name} checked={row2.checked} onChange={handleFormMyChoiceInputChange} key={index + "-" + index2} custom />
                    </Col>
                  </Form.Group>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button type="submit" variant="primary" className="btn-fill mr-sm-2"><i className="fas fa-check"></i> ตกลง</Button>
            <Button type="button" variant="default" className="btn-fill" onClick={() => setShowFormMyChoice(false)}><i className="fas fa-times"></i> ปิด</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="md"
        backdrop="static"
        show={showFormDisApprove}
        onHide={() => setShowFormDisApprove(false)}
      >
        <Form noValidate validated={validatedFormDisApprove} onSubmit={formDisApproveSubmit}>
          <Modal.Header className="justify-content-center">
            <Modal.Title>สาเหตุการไม่อนุมัติ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Col sm="12">
                <Form.Control as="textarea" name="remark" value={formDisApprove.remark} onChange={handleFormDisApproveInputChange} required rows="5"></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน สาเหตุการไม่อนุมัติ</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button type="submit" variant="primary" className="btn-fill mr-sm-2"><i className="fas fa-check"></i> ตกลง</Button>
            <Button type="button" variant="default" className="btn-fill" onClick={() => setShowFormDisApprove(false)}><i className="fas fa-times"></i> ปิด</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size="md"
        show={showStatus}
        onHide={() => setShowStatus(false)}
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title>สถานะเอกสาร</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="4">วันที่สร้างเอกสาร</Col>
            <Col sm="8">{docStatus.master.created_at}</Col>
          </Row>
          <Row>
            <Col sm="4">ประเภทเอกสาร</Col>
            <Col sm="8">{docStatus.master.type_name}</Col>
          </Row>
          <Row>
            <Col sm="4">ชื่อเอกสาร</Col>
            <Col sm="8">{docStatus.master.doc_name}</Col>
          </Row>
          
          <Table striped bordered hover className="mt-2">
            <thead>
              <tr>
                <th width="150"></th>
                <th>สถานะ</th>
              </tr>
            </thead>
            <tbody>
              {docStatus.detail.map((row, index) => (
                <tr key={index}>
                  <td>{row.updated_at}</td>
                  <td className={row.status_class}>{row.status_text}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button type="button" variant="primary" className="btn-fill mr-sm-2" onClick={showDoc}><i className="fas fa-file-alt"></i> ดูเอกสาร</Button>
          <Button type="button" variant="default" className="btn-fill" onClick={() => setShowStatus(false)}><i className="fas fa-times"></i> ปิด</Button>
        </Modal.Footer>
      </Modal>

      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
      {
        confirm.show && <SweetAlert
                        title={confirm.text}
                        btnSize="md"
                        onConfirm={confirm.onConfirm}
                        confirmBtnText={confirm.confirmBtnText}
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}}
                        showCancel={true}
                        onCancel={confirm.onCancel}
                        cancelBtnText={confirm.cancelBtnText}
                        cancelBtnBsStyle="default"
                        cancelBtnCssClass="btn-fill"
                        cancelBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
      {
        confirmSend.show && <SweetAlert
                        title={confirmSend.text}
                        btnSize="md"
                        onConfirm={handleConfirmSend}
                        confirmBtnText={confirmSend.confirmBtnText}
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}}
                        showCancel={true}
                        onCancel={confirmSend.onCancel}
                        cancelBtnText={confirmSend.cancelBtnText}
                        cancelBtnBsStyle="default"
                        cancelBtnCssClass="btn-fill"
                        cancelBtnStyle={{"border":"none","boxShadow":"none"}}
                        dependencies={[form.school_user_id]}>
                          {(renderProps) => (
                            confirmSend.showSend && <>
                              <Form.Group as={Row}>
                                <Col sm="12">
                                  <Form.Control as="select" id="send_school_user_id" name="school_user_id" value={form.school_user_id} onChange={handleFormInputChange} disabled={!form.is_route}>
                                    {user.map((row, index) => <option value={row.id} key={index}>{row.fullname}</option>)}
                                  </Form.Control>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Col sm="12">
                                  <Form.Check type="checkbox" id="is_route" name="is_route" value="1" label="แทรกผู้เซ็นท่านนี้" checked={form.is_route} onChange={handleFormInputChange} custom />
                                </Col>
                              </Form.Group>
                            </>
                          )}
                        </SweetAlert>
      }
    </>
  );
}

export default Doc;
